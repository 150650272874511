import React, {useEffect, useRef} from "react";
import nyanCatImage from './doge.png';
import rainbowImage from './rainbow.png';
import rainbowImage2 from './rainbow2.png';
import xImage from './x.png';

const SCENE_PARAMS = [
    {
        s: 1/3,
        f: 2/3,
        condition: (scene) => scene.zcr > 28,
        gradientColors: ["#fde00c", "#ffff77"],
        emoji: "🪐",
        moon: "🌚",
        speed: 8,
        emojiSelect: (i, j) => (i + j) % 2 === 0,
    },
    {
        s: 1/3,
        f: 2/3,
        condition: (scene) => scene.zcr > 21,
        gradientColors: ["#000000", "#ff5832"],
        emoji: "🌔",
        moon: "🌘",
        speed: 7,
        emojiSelect: (i, j) => (i + j) % 2,
    },
    {
        s: 1/3,
        f: 2/3,
        condition: (scene) => scene.zcr > 18,
        gradientColors: ["#ffffff", "#aaaaaa"],
        emoji: "💀",
        moon: "🌗",
        speed: 6,
        emojiSelect: (i, j) => (i + j) % 2 === 0,
    },
    {
        s: 1/3,
        f: 2/3,
        condition: (scene) => scene.rms > 0.2,
        gradientColors: ["#000000", "#0079ff"],
        emoji: "🔊",
        moon: "🌖",
        speed: 5,
        emojiSelect: (i, j) => i % 2 === 0 && j % 2 === 0,
    },
    {
        s: 0,
        f: 1/3,
        condition: (scene) => scene.zcr < 10 && scene.rms < 0.1,
        gradientColors: ["#000000", "#555555"],
        emoji: "🌚",
        moon: "🌕",
        speed: 3,
        emojiSelect: (i, j) => (i + j) % 2 === 0,
    },
    {
        s: 0,
        f: 1/3,
        condition: (scene) => scene.rms < 0.1,
        gradientColors: ["#fffcdf", "#fde00c"],
        emoji: "🍔",
        moon: "🌔",
        speed: 4,
        emojiSelect: (i, j) => (i + j) % 2,
    },
    {
        s: 0,
        f: 2/3,
        condition: (scene) => scene.zcr < 15,
        gradientColors: ["#000000", "#77ffff"],
        emoji: "💣",
        moon: "🌓",
        speed: 4,
        emojiSelect: (i, j) => (i + j) % 2 === 0,
    },
    {
        s: 1/3,
        f: 2/3,
        condition: () => true,
        gradientColors: ["#ff00ff", "#ff77ff"],
        emoji: "🎵",
        moon: "🌒",
        speed: 2,
        emojiSelect: (i, j) => (i + j) % 2,
    },
];

const moons = ["🌑", "🌒", "🌓", "🌔", "🌕", "🌖", "🌗", "🌘"];

const GeometryDashVisualizer = ({audioData, scenes}) => {
    const canvasRef = useRef();
    const animationRef = useRef();
    const platforms = useRef([]);
    const ballYRef = useRef(0);
    const ballVelocityRef = useRef(0);
    const backgroundXRef = useRef(0);
    const sunXRef = useRef(0);
    const sunYRef = useRef(0);
    const sunDirectionRef = useRef({x: 1.2, y: 1.2});
    const nyanCatXRef = useRef(0);
    const nyanCatYRef = useRef(0);
    const nyanCatDirectionRef = useRef({x: 1.2, y: 1.2});
    const dollars = useRef(0.0);
    const timeRef = useRef(0);
    const colorIndexRef = useRef(0);
    const moonIndexRef = useRef(0);
    const colors = ["rgba(255, 0, 0, 1)", "rgba(0, 255, 0, 1)", "rgba(0, 0, 255, 1)", "rgba(255, 255, 0, 1)", "rgba(0, 255, 255, 1)", "rgba(255, 0, 255, 1)"];
    const flipRainbow = useRef(false)

    useEffect(() => {
        const fetchDogecoinPrice = async () => {
            try {
                const response = await fetch('https://api.coinbase.com/v2/prices/DOGE-USD/spot');
                const data = await response.json();
                dollars.current = parseFloat(data.data.amount) * 10000; // Convert to cents
            } catch (error) {
                console.error('Error fetching Dogecoin price:', error);
            }
        };

        fetchDogecoinPrice();


        const canvas = canvasRef.current;
        let ballY = canvas?.height / 2;
        let ballVelocity = 0;
        const gravity = 0.3;
        const jumpStrength = -10;

        const ctx = canvas?.getContext("2d");
        const nyanCat = new Image();
        nyanCat.src = nyanCatImage;
        const rainbow = new Image();
        rainbow.src = rainbowImage;
        const rainbow2 = new Image();
        rainbow2.src = rainbowImage2;
        const x = new Image();
        x.src = xImage;

        const getSceneParams = (scene) => {
            return SCENE_PARAMS.find((params) => params.condition(scene));
        };

        const getAverage = (data) => {
            return data.reduce((sum, value) => sum + value, 0) / data.length;
        };

        const createPlatforms = () => {
            if (platforms.current.length === 0) {
                // platforms.current = [];
                for (let i = 0; i < canvas.width; i += 300) {
                    platforms.current.push({
                        x: i,
                        y: canvas.height - 100,
                        width: 250,
                        height: 30,
                    });
                }
            }
        };

        const drawScene = (scene) => {
            const {emojiSelect, gradientColors, emoji, speed, s, f} = getSceneParams(scene);

            ctx.clearRect(0, 0, canvas.width, canvas.height);

            backgroundXRef.current -= speed / 2;
            if (backgroundXRef.current <= -canvas.width) {
                backgroundXRef.current = 0;
            }

            platforms.current.forEach(platform => {
                platform.x -= speed * 1.5;
                if (platform.x + platform.width < 0) {
                    platform.x = canvas.width;
                }
            })

            // Фон с градиентом
            const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
            gradient.addColorStop(0, gradientColors[0]);
            gradient.addColorStop(1, gradientColors[1]);
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            if (audioData && audioData.length > 0) {
                const C = 50;
                const barWidth = canvas.width / C;
                let x = 0;

                for (let i = 0; i < C; i = i + 2) {
                    const start = Math.floor((audioData.length / C) * i);
                    const end = Math.floor((audioData.length / C) * (i + 1));
                    const segment = audioData.slice(start, end);
                    const avg = segment.reduce((sum, value) => sum + value, 0) / segment.length;
                    const barHeight = (avg / 255) * canvas.height / 2;

                    ctx.fillStyle = `rgba(0, ${barHeight}, 0, 1)`;
                    ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
                    x += barWidth + 1;
                }

                for (let i = C; i > 0; i = i - 2) {
                    const start = Math.floor((audioData.length / C) * i);
                    const end = Math.floor((audioData.length / C) * (i + 1));
                    const segment = audioData.slice(start, end);
                    const avg = segment.reduce((sum, value) => sum + value, 0) / segment.length;
                    const barHeight = (avg / 255) * canvas.height / 2;

                    ctx.fillStyle = `rgba(${barHeight}, 0, 100, 1)`;
                    ctx.fillRect(x, canvas.height - barHeight, barWidth, barHeight);
                    x += barWidth + 1;
                }
            }

            // const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
            // gradient.addColorStop(0, gradientColors[0]);
            // gradient.addColorStop(1, gradientColors[1]);
            // ctx.fillStyle = gradient;
            // ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Эмоджи на фоне
            const avg = getAverage(audioData.slice(Math.floor(audioData.length * s), Math.floor(audioData.length * f))) || 0;

            const dogeSize = 50 + Math.pow(avg, 3) / 50_000;
            ctx.fillStyle = 'rgba(0, 0, 0, .5)';
            ctx.font = `bold ${dogeSize}px Arial`;
            const cX = canvas.width / 2;
            const cY = canvas.height / 2;

            const DOGE_TEXT = 'D.O.G.E.';
            const textMetrics = ctx.measureText(DOGE_TEXT);
            const textWidth = textMetrics.width;

            ctx.fillText(DOGE_TEXT, cX - textWidth/2, cY + dogeSize / 2);

            const emojiSize = 20 + Math.pow(avg, 3) / 100_000;

            ctx.fillStyle = `rgba(0, 0, 0, ${emoji === '🌔' ? 1 : .5})`;
            const curEmoji = emoji === '🌔' ? moons[moonIndexRef.current] : emoji;
            for (let i = 0; i < canvas.width; i += 80) {
                for (let j = 40; j < canvas.height; j += 80) {

                    ctx.font = `${emojiSelect(i/80, (j - 40)/80) ? emojiSize : (100 - emojiSize)}px Arial`;
                    ctx.fillText(curEmoji, backgroundXRef.current + i, j);
                    ctx.fillText(curEmoji, backgroundXRef.current + i + canvas.width, j);
                }
            }

            // ctx.drawImage(x, canvas.width - 80, canvas.height - 40, 64, 25);

            // Перемещение солнца
            sunXRef.current += sunDirectionRef.current.x;
            sunYRef.current += sunDirectionRef.current.y;

            if (sunXRef.current <= 0 || sunXRef.current >= canvas.width * 3 / 4) {
                sunDirectionRef.current.x *= -1;
            }
            if (sunYRef.current <= 0 || sunYRef.current >= canvas.height / 2) {
                sunDirectionRef.current.y *= -1;
            }

            nyanCatXRef.current += nyanCatDirectionRef.current.x;
            timeRef.current += 0.4;
            nyanCatYRef.current += nyanCatDirectionRef.current.y;

            if (nyanCatXRef.current <= 0 || nyanCatXRef.current >= canvas.width * 2.2 / 4) {
                nyanCatDirectionRef.current.x *= -1;
            }
            if (nyanCatYRef.current <= 0 || nyanCatYRef.current >= canvas.height / 2) {
                nyanCatDirectionRef.current.y *= -1;
            }

            const nyanCatX = nyanCatXRef.current;
            const nyanCatY = nyanCatYRef.current + Math.sin(timeRef.current) * 7;

            const sunX = canvas.width - 100; // sunXRef.current
            const sunY = canvas.height * 1 / 3; // sunYRef.current;
            const _sunOffset = 70 + Math.sin(timeRef.current) * 7;
            const lightGradient = ctx.createRadialGradient(sunX, sunY, 10 + Math.pow(avg, 3) / 80000, sunX, sunY, 121 + Math.pow(avg, 3) / 80000);
            lightGradient.addColorStop(0, 'rgba(255, 255, 255, .5)');
            lightGradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
            ctx.fillStyle = lightGradient;
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Draw moon emoji over the sun
            const moonSize = 30 + Math.pow(avg, 3) / 80_000;
            ctx.fillStyle = 'rgba(0, 0, 0, 1)';
            ctx.font = `${moonSize}px Arial`;
            ctx.fillText(moons[moonIndexRef.current], sunX - moonSize/2, sunY + moonSize / 2 - 10);

            // Рисуем радугу позади кота
            for (let i = 0; i < 10; i++) {
                ctx.drawImage(flipRainbow.current ? rainbow2 : rainbow, nyanCatX + 20 - (i + 1) * 79, nyanCatY + 40, 80, 60);
            }

            ctx.drawImage(nyanCat, nyanCatX - 40, nyanCatY, 160, 120);

            // Деньги
            let fontSize = 50;
            ctx.font = `${fontSize}px Arial`;
            ctx.fillStyle = colors[colorIndexRef.current];
            ctx.fillText(`$${(dollars.current / 10000).toFixed(4)}`, nyanCatX - 30 - fontSize/2, nyanCatY);

            colorIndexRef.current = (colorIndexRef.current + 1) % colors.length;

            // Платформы
            platforms.current.forEach(platform => {
                const shadowOffsetX = (platform.x - sunX) ;
                const shadowOffsetY = (platform.y - sunY);

                // Тень под платформой
                ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
                ctx.beginPath();
                if (shadowOffsetX > 0) {
                    ctx.moveTo(platform.x, platform.y + platform.height);
                    ctx.lineTo(platform.x + platform.width, platform.y);
                    ctx.lineTo(platform.x + platform.width + shadowOffsetX, platform.y + platform.height + shadowOffsetY);
                    ctx.lineTo(platform.x + shadowOffsetX, platform.y + platform.height + shadowOffsetY);
                } else {
                    ctx.moveTo(platform.x, platform.y);
                    ctx.lineTo(platform.x + platform.width, platform.y + platform.height);
                    ctx.lineTo(platform.x + platform.width + shadowOffsetX, platform.y + shadowOffsetY);
                    ctx.lineTo(platform.x + shadowOffsetX, platform.y + shadowOffsetY);
                }
                ctx.closePath();
                ctx.fill();

                ctx.fillStyle = "black";
                ctx.fillRect(platform.x, platform.y, platform.width, platform.height);
            });

            // Шарик
            ballVelocity += gravity;
            ballY += ballVelocity;

            // Проверка столкновения с платформами
            // let onPlatform = false;
            // platforms.current.forEach(platform => {
            //     if (ballY + 25 >= platform.y && ballY + 25 <= platform.y + platform.height && 100 >= platform.x && 100 <= platform.x + platform.width) {
            //         ballY = platform.y - 25;
            //         ballVelocity = 0;
            //         onPlatform = true;
            //     }
            // });

            // if (!onPlatform && ballY > canvas.height - 75) {
            //     ballY = canvas.height - 75;
            //     ballVelocity = jumpStrength;
            // }
            if (ballY > canvas.height - 125) {
                ballY = canvas.height - 125;
                ballVelocity = jumpStrength;
            }

            // ctx.fillStyle = "black";
            // ctx.beginPath();
            // ctx.arc(100, ballY, 25, 0, 2 * Math.PI);
            // ctx.fill();

            ballYRef.current = ballY;
            ballVelocityRef.current = ballVelocity;
        };

        const draw = () => {
            if (scenes.length > 0) {
                const currentScene = scenes[scenes.length - 1];
                drawScene(currentScene);
            }
            animationRef.current = requestAnimationFrame(draw);
        };

        if (scenes.length > 0) {
            createPlatforms();
        }

        draw();

        // const interval = setInterval(() => {
        //     dollars.current += 1;
        // }, 10);

        const flipInterval = setInterval(() => {
            flipRainbow.current = !flipRainbow.current;
        }, 400 / getSceneParams(scenes).speed);

        const moonInterval = setInterval(() => {
            moonIndexRef.current = (moonIndexRef.current + 1) % moons.length;
        }, 200 / getSceneParams(scenes).speed);

        return () => {
            cancelAnimationFrame(animationRef.current);
            // clearInterval(interval);
            clearInterval(flipInterval);
            clearInterval(moonInterval);
        };
    }, [scenes]);

    useEffect(() => {
        const handleResize = () => {
            const canvas = canvasRef.current;
            if (canvas) {
                canvas.width = window.innerWidth;
                canvas.height = window.innerHeight;
            }
        };

        window.addEventListener('resize', handleResize);

        // Устанавливаем начальную ширину canvas
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <>
        <canvas ref={canvasRef} width="800" height="400" style={{background: "#000"}}/>
{/*
        <div>
            <div>{scenes[scenes.length - 1]?.rms}</div>
            <div>{scenes[scenes.length - 1]?.zcr}</div>
            <div>{scenes[scenes.length - 1]?.spectralCentroid}</div>
        </div>
*/}
    </>
};
export default GeometryDashVisualizer;